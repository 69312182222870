const dashboard = {
    hello: 'Welcome, ',
    totalBalance: 'Total Balance in',
    total: 'Total Balance',
    asOn: 'as on ',
    availableBalance: 'Available Balance',
    liquidityReserve: 'Liquidity Reserve',
    notification: 'Notification',
    pendingBalance: 'Pending Balance',
    createABeneficiary: 'Create a beneficiary',
    sendSingleEFT: 'Send Single EFT',
    sendWire: 'Send Wire',
    sentEFT: 'Sent EFT',
    receivedEFT: 'Received EFT',
    sentWire: 'Sent Wire',
    dateRange: 'Date Range',
    type: 'Type',
    status: 'Status',
    clearFilter: 'Clear filter',
    viewCompleteHistory: 'View complete history',
    number: 'Number',
    receiver: 'Receiver',
    dateAndTime: 'Date and Time',
    amount: 'Amount',
    description: 'Description',
    wireStatement: 'Wire Statement',
    viewAll: 'View all',
    filter: 'Filter',
    pendingTransac: 'Pending Transactions',
    processedTransac: 'Processed Transactions',
    settledTransac: 'Settled Transactions',
    returnedTransac: 'Returned Transactions',
    rejectedTransac: 'Rejected Transactions',
    recallTransac: 'Recall Transactions',
    pending: 'Pending',
    processed: 'Processed',
    settled: 'Settled',
    returned: 'Returned',
    rejected: 'Rejected',
    recall: 'Recall',
    wallet: 'Wallet',
    currency: 'Currency',
    unknown: 'Unknown',
    allStatus: 'All Status',
    allWallet: 'All Wallet',
    allCurrency: 'All Currency',
    apply: 'Apply',
    selectCurrency: 'Select currency',
    dateRangePlaceholder: 'Select date range'
}

export default dashboard
