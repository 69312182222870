import { ConfigProvider } from 'antd'
import './App.css'
import './i18n'
import RoutesComponents from './router'

function App() {
    return (
        <ConfigProvider theme={{ token: { colorPrimary: '#05004D' } }}>
            <RoutesComponents />
        </ConfigProvider>
    )
}

export default App
