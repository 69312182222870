import GeneralModal from 'components/beneficiary/GeneralModal'
import { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { logout } from 'utils/common'

const AutoLogoutModal = ({ isIdle, resetIdleTimer }) => {
    const { t } = useTranslation('layout')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [countdown, setCountdown] = useState(30)

    useEffect(() => {
        if (isIdle) {
            setIsModalOpen(true)
        }
    }, [isIdle])

    useEffect(() => {
        if (isModalOpen) {
            setCountdown(30)

            const timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 1) {
                        clearInterval(timer)
                        setIsModalOpen(false)
                        logout()
                        return 0
                    }
                    return prev - 1
                })
            }, 1000)

            return () => clearInterval(timer)
        }
    }, [isModalOpen])

    const handleStayLoggedIn = () => {
        setIsModalOpen(false)
        resetIdleTimer()
    }

    return (
        <GeneralModal
            open={isModalOpen}
            setOpen={setIsModalOpen}
            okBtnContent={t('imStillHere')}
            okButtonClass="bg-virgo-pay-primary"
            title={t('stillThere')}
            description={
                <Trans
                    i18nKey={t('layout:stillThereDesc')}
                    values={{ time: countdown }}
                    components={{ bold: <span className="font-bold" /> }}
                />
            }
            cancelBtnContent={t('logoutNow')}
            customHandleOk={handleStayLoggedIn}
            customHandleCancel={logout}
            closable={false}
        />
    )
}

export default AutoLogoutModal
