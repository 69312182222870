const login = {
    welcome: '欢迎',
    confirmEmail: '确认电子邮件',
    email: '电子邮件',
    next: '下一个',
    setPassword: '设置密码',
    password: '密码',
    confirmPassword: '确认密码',
    confirm: '确认',
    googleMicrosoftAuthenticator: 'Google/Microsoft 身份验证器',
    authenticatorInstruction:
        '请打开您的 Google/Microsoft 身份验证器应用程序并扫描二维码或粘贴密钥以添加您的 VirgoCX Wealth 帐户。',
    dontKnowHowToSetUpAuthenticator: '您不知道如何设置身份验证器吗？',
    googleAuthenticatorInstruction: 'Google 身份验证器说明',
    microsoftAuthenticatorInstruction: 'Microsoft 身份验证器说明',
    submit: '提交',
    allSet: '一切准备就绪！',
    accountActivated: '您的帐户现已激活。',
    compelete2FA: '请完成 2FA 过程。',
    continue: '继续',
    loginMessage: '使用您的电子邮件和新密码登录以访问您的帐户',
    login: '登录',
    forgotPassword: '忘记密码？',
    smsAuthenticator: '短信',
    enterSMSCode: '请输入发送到 +1 的代码 ',
    enterAuthenticatorCode: '请输入 Google/Microsoft 身份验证器应用程序中的代码。',
    loginSuccessful: '登录成功。',
    enterPhone: '请输入可以接收短信的电话号码。',
    registeredEmail: '注册的电子邮件',
    checkYourEmail: '检查你的电子邮件',
    emailSent: '电子邮件已发送到',
    backToLogin: '返回登录',
    resetPwd: '重置密码',
    createNewPwd: '请创建您的新密码。',
    newPwd: '新密码',
    confirmNewPwd: '确认新密码',
    symbolsAreAllowed: '需要 8-15 个字符，允许使用符号。',
    onletter: '至少包含一个数字和一个字母。',
    doesNotMatch: '密码不匹配。',
    linkExpired: '链接已过期',
    linkExpiredDesc: '请从登录页面请求新的密码重置链接。',
    done: '完成',
    samePwdError: '新密码不能与当前密码相同。请选择不同的密码。',
    verification: '验证',
    enterEmailVerificationCode: '请输入电子邮件验证码',
    enterSMSVerificationCode: '请输入短信验证码',
    enterAuthVerificationCode: '请输入身份验证应用程序上的代码',
    emailVerification: '电子邮件验证',
    SMSVerification: '短信验证',
    authVerification: '身份验证应用程序',
    back: '返回',
    successReset: '重置成功！',
    pwdModified: '登录密码已成功修改。'
}

export default login
