const notification = {
    notification: 'Notification',
    approvals: 'Approvals',
    general: 'General',
    status: 'Status',
    reviewDetails: 'Review the details',
    approve: 'Approve',
    decline: 'Decline',
    done: 'Done',
    delete: 'Delete',
    initDate: 'Date of Initiation',
    approvalMsg: 'Approval Successful! The request has been approved.',
    declinedMsg: 'Request Declined! The request has been successfully declined.',
    approvalErrorMsg:
        'Approval Failed. Please try again later or contact support if the issue persists.',
    newBeneficiary: 'New Beneficiary Detail',
    prevBeneficiary: 'Previous Beneficiary Detail',
    noNotifications: 'Currently No Notifications.',
    transferDetails: 'Transfer Details',
    logDesc: '{{status}} {{createTime}} by {{userName}}',
    fromWalletId: 'From Wallet ID'
}

export default notification
