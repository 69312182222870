const account = {
    profile: '个人资料',
    rolesAndPermission: '角色与权限',
    billing: '账单',
    setting: '设置',
    corporateInformation: '公司信息',
    personalInformation: '个人信息',
    addANewUser: '添加一个新的用户',
    name: '姓名',
    designation: '职位',
    emailAddress: '电子邮件地址',
    permission: '权限',
    status: '状态',
    read: '读取',
    action: '操作',
    roles: '角色与权限',
    userInformation: '用户信息',
    provideDetails: '请提供详细信息',
    firstName: '姓',
    middleNameOptional: '中见名（可选）',
    lastName: '名',
    dateOfBirth: '出生日期',
    occupation: '职位',
    percentageOfOwnership: '所有权百分比',
    address: '地址',
    city: '城市',
    country: '国家',
    provinceState: '州/省',
    postalCode: '邮编',
    phoneNumber: '电话号码',
    email: '电子邮件地址',
    instantMessageAppOptional: '及时通讯应用（可选）',
    appID: '应用ID',
    admin: '管理员',
    initiator: '发起人',
    approver: '批准人',
    teamMember: '成员',
    initiate: '发起',
    approve: '批准',
    userManagement: '用户管理',
    readDes: '查看所有交易并下载报告',
    beneficiaryCreation: '收款人创建',
    transactionCreation: '交易创建',
    eft: 'EFT',
    batchEFT: '批量EFT',
    wire: '电汇',
    viewAllTransaction: '查看所有交易',
    downloadReports: '下载报告',
    approvalAuthority: '审批权限',
    pepQuestion: '此人是否为根据 FATF 指南定义的政治公众人物 (PEP)？',
    yes: '是',
    no: '否',
    uploadDocuments: '上传文件',
    identification: '身份验证',
    idInstructions: '护照、驾驶执照正反面或其他政府签发的身份证明',
    proofOfAddress: '地址证明',
    proofOfAddressInstructions: '3 个月内的原始 PDF 格式的公用事业账单或银行对账单',
    submit: '提交',
    onboardingRequestSubmitted: '开户请求已提交',
    onboardingRequestMessage: '新用户开户请求已提交，需要另一位管理员批准。',
    done: '完成',
    tutorial: '教程',
    faq: '常见问题',
    account: '账户',
    signOut: '退出登录',
    password: '密码',
    loginPassword: '登录密码',
    verification: '验证',
    googleMicrosoftAuthenticator: 'Google/Microsoft 验证器',
    update: '更新',
    delete: '删除',
    setNewLoginPassword: '设置您的新登录密码',
    enterAllInput: '请输入以下所有信息。',
    currentPassword: '当前密码',
    emailVerification: '邮件验证',
    emailVerificationCode: '邮件验证码',
    newPassword: '新密码',
    smsVerification: '短信验证',
    smsVerificationCode: '短信验证码',
    confirmNewPassword: '确认新密码',
    notBound: '未绑定',
    googleMicrosoftAuthenticatorCode: 'Google/Microsoft 验证器代码',
    enterAllCodes: '请输入以下所有代码以完成。',
    resetToLogin: '密码重置成功，请重新登录',
    enterTheCode: '请输入代码',
    allSet: '全部设置完成！',
    verificationUpdated: '您的验证码已更新。',
    confrim: '确认',
    back: '返回',
    feesStatement: '费用明细',
    invoiceNum: '发票编号',
    date: '日期',
    doesNotMatch: '密码不匹配',
    twoFactorAuthRequired: '需要双重身份验证 (2FA)',
    twoFactorAuthSetupWarning:
        '您必须至少拥有一种活动形式的双重身份验证 (2FA)。请在取消链接身份验证器应用之前设置另一种 2FA 方法。'
}

export default account
