const sendMoney = {
    sendMoneyBy: '通过以下方式汇款',
    singleEft: '单一电子资金转账',
    batchEft: '批量电子资金转账',
    wire: '电汇',
    wireTransfer: '电汇转账',
    sendMoney: '汇款',
    electronicFundTransfer: '电子资金转账',
    availableAmount: '可用金额',
    dailyAvailable: '每日可用',
    send: '发送',
    sendSingleEft: '发送单一电子资金转账',
    to: '至',
    from: '从',
    beneficiary: '受益人',
    wallet: '钱包',
    selectWallet: '钱包选择',
    currency: '币种',
    provideDetails: '请提供您的详细信息',
    amount: '金额',
    available: '可用',
    transactionType: '交易类型',
    transactionDate: '交易日期',
    paymentPurpose: '付款目的',
    description: '描述',
    sundryInfo: '杂项信息（可选）',
    clear: '清除',
    submit: '提交',
    review: '审查',
    reviewDetails: '查看转账详情',
    reviewDetailsDesc: '在转账之前，请仔细核对详细信息是否正确。',
    confirm: '确认',
    cancel: '取消',
    transferSubmitted: '转账已提交',
    transactionNumber: '交易编号：',
    transferSubmittedDesc: '转账请求将由授权签署人或管理员审核批准。通知已发送给他们。',
    done: '完成',
    viewTransaction: '查看交易',
    transaction: '交易',
    sendBathEFT: '发送批量电子资金转账',
    uploadBatchEFT: '上传批量电子资金转账',
    upload: '上传',
    uploadFilesDesc1: '仅限 xlsx 文件。最大文件大小 6MB',
    uploadFilesDesc2: '拖放批量文件到此处或点击此处',
    prevFiles: '先前的文件',
    fileName: '文件名',
    uploadTime: '上传时间',
    action: '操作',
    eftTemplate: '电子资金转账模板',
    sendBatchEFT: '发送批量电子资金转账',
    sendWire: '发送电汇',
    credit: '贷方',
    totalSend: '总发送',
    fees: '费用',
    totalAmount: '总金额',
    exceedAmount: '请输入每日可用金额范围内的金额。',
    clearTransac: '清除交易',
    clearTransacDesc: '您确定要清除此交易吗？',
    changeTransac: '更改交易详细信息',
    changeTransacDesc: '您想更改此交易详细信息吗？',
    walletTrans: '钱包转账',
    walletTransDesc: '转账至另一位 VirgoPay 用户',
    walletIdInputPlaceholder: '受益人 Virgo Pay 钱包 ID',
    senderWalletId: '发款人钱包ID',
    back: '返回',
    yes: '是',
    no: '否',
    continue: '继续',
}

export default sendMoney
