const transaction = {
    transactionHistory: 'Transaction History',
    transactionDetails: 'Transaction Details',
    sentEFT: 'Sent EFT',
    receivedEFT: 'Received EFT',
    sentWire: 'Sent Wire',
    receivedWire: 'Received Wire',
    walletTransfer: 'Wallet Transfer',
    dateRange: 'Date Range',
    type: 'Type',
    status: 'Status',
    clearFilter: 'Clear filter',
    downloadAll: 'Download All',
    clear: 'Clear',
    receiver: 'Receiver',
    dateAndTime: 'Date & Time',
    sender: 'Sender',
    number: 'Number',
    direction: 'Direction',
    uniqueID: 'Unique ID',
    amount: 'Amount',
    action: 'Action',
    debit: 'Debit',
    pending: 'Pending',
    credit: 'Credit',
    settled: 'Settled',
    returned: 'Returned',
    perPage: 'Per page',
    totalSent: 'Total Sent',
    fee: 'Fee',
    totalAmount: 'Total Amount',
    // detail
    bankNumber: 'Bank Number',
    receiverTransitNumber: 'Receiver Transit number',
    receiverAccountNumber: 'Receiver Account number',
    purposeOfPayment: 'Purpose of Payment',
    description: 'Description',
    sundryInformationOptional: 'Sundry Information (optional)',
    crossReferenceNumber: 'Cross Reference Number',
    returnCode: 'Return Code',
    returnDate: 'Return Date',
    returnReason: 'Return Reason',
    downloadSuccessful: 'Download successful!',
    pleaseSelect: 'Please select the row you want to download',
    receiverWalletId: 'Receiver Wallet ID',
    senderWalletId: 'Sender Wallet ID',
    back: 'Back'
}

export default transaction
