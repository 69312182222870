const common = {
    test: '测试',
    getCode: '发送验证码',
    resend: '重新发送({{state}}秒)',
    myAcc: '我的账户',
    logout: '退出登录',
    uploader1: '拖放文件或',
    uploader2: '上传',
    uploaderComment: '仅支持 png、jpg、jpeg 和 pdf 文件，最大文件大小 15MB',
    FileTypeLimit: '您只能上传 JPG/PNG/PDF 文件！',
    FileSizeLimit: '文件大小必须小于 15MB！',
    notFoundH: '页面未找到',
    notFoundC: '抱歉，我们无法找到您要访问的页面。请检查您的 URL。',
    OTCDirect: 'OTC Direct',
    ChatBroker: '在线人工服务',
    Learn: '学习',
    Earn$200: '赚取$200+',
    welcome: '欢迎回来',
    plsEnter: '请输入',
    delete: '删除',
    onlySapceDisallowd: '空格不被单独允许输入这里',
    error404: '错误404',
    pageNotFound: '页面不存在',
    notFoundMsg: '抱歉，我们无法找到您要前往的页面。<br/>请检查您输入的网址。',
    systemError: '哎呀！看起来好像少了点什么。',
    systemErrorMsg: '没有找到对应的信息，请检查之后，刷新页面重试。',
    mainPage: '主页'
}
export default common
