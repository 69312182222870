const login = {
    welcome: 'Welcome',
    confirmEmail: 'Confirm email',
    email: 'Email',
    next: 'Next',
    setPassword: 'Set password',
    password: 'Password',
    confirmPassword: 'Confirm password',
    confirm: 'Confirm',
    googleMicrosoftAuthenticator: 'Google/Microsoft Authenticator',
    authenticatorInstruction:
        'Please open your Google/Microsoft Authenticator app and scan the QR code or paste the key to add your VirgoCX Wealth account.',
    dontKnowHowToSetUpAuthenticator: 'Don’t you know how to set up Authenticator?',
    googleAuthenticatorInstruction: 'Google Authenticator Instruction',
    microsoftAuthenticatorInstruction: 'Microsoft Authenticator Instruction',
    submit: 'Submit',
    allSet: 'All set!',
    accountActivated: 'Your account is now activated.',
    compelete2FA: 'Please complete the 2FA process.',
    continue: 'Continue',
    loginMessage: 'Log in using your email and new password to access your account',
    login: 'Log In',
    forgotPassword: 'Forgot Password?',
    smsAuthenticator: 'SMS',
    enterSMSCode: 'Please enter the code sent to +1 ',
    enterAuthenticatorCode: 'Please enter the code on your Google/Microsoft authenticator app.',
    loginSuccessful: 'Login Successful.',
    enterPhone: 'Please enter the phone number that can receive SMS.',
    registeredEmail: 'Registered Email',
    checkYourEmail: 'Check your email',
    emailSent: 'An email has been sent to',
    backToLogin: 'Back to login',
    resetPwd: 'Reset Password',
    createNewPwd: 'Please create your new password.',
    newPwd: 'New Password',
    confirmNewPwd: 'Confirm New Password',
    symbolsAreAllowed: 'Need to be 8-15 characters, symbols are allowed.',
    onletter: 'At least one number and one letter.',
    doesNotMatch: 'Password does not match.',
    linkExpired: 'Link Expired',
    linkExpiredDesc: 'Please request a new password reset link from the login page.',
    done: 'Done',
    samePwdError:
        'New password cannot be the same as the current password. Please choose a different password.',
    verification: 'Verification',
    enterEmailVerificationCode: `Please enter the email verification code`,
    enterSMSVerificationCode: `Please enter the SMS verification code`,
    enterAuthVerificationCode: `Please enter the code on the authenticator app`,
    emailVerification: 'Email Verification',
    SMSVerification: 'SMS Verification',
    authVerification: 'Authentication App',
    back: 'Back',
    successReset: 'Successfully reset!',
    pwdModified: 'The login password was successfully modified.'
}

export default login
