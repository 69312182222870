import common from './zh/common'
import dashboard from './zh/dashboard'
import transaction from './zh/transaction'
import account from './zh/account'
import login from './zh/login'
import layout from './zh/layout'
import beneficiary from './zh/beneficiary'
import sendMoney from './zh/sendMoney'
import personalInfo from './zh/personalInfo'
import notification from './zh/notification'

const zh = {
    common,
    login,
    dashboard,
    transaction,
    account,
    layout,
    beneficiary,
    sendMoney,
    personalInfo,
    notification
}

export default zh
