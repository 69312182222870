const notification = {
    notification: '通知',
    approvals: '审批',
    general: '一般',
    status: '状态',
    reviewDetails: '查看详细信息',
    approve: '批准',
    decline: '拒绝',
    done: '完成',
    delete: '删除',
    initDate: '发起日期',
    approvalMsg: '审批成功！请求已被批准。',
    declinedMsg: '请求已被拒绝！请求已成功拒绝。',
    approvalErrorMsg: '审批失败。请稍后重试或联系支持人员。',
    newBeneficiary: '新受益人详细信息',
    prevBeneficiary: '以前的受益人详细信息',
    noNotifications: '目前没有通知。',
    transferDetails: '转账详情',
    logDesc: '{{status}} {{createTime}} 由 {{userName}}',
    fromWalletId: '来自钱包ID'
}

export default notification
