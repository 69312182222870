import { create } from 'zustand'

const usePersonalProfile = create((set) => ({
    personalProfile: {},
    savePersonalProfile: (v) =>
        set(() => ({
            personalProfile: v
        })),
    resetPersonalProfile: () => set({ personalProfile: {} })
}))
const useEntityProfile = create((set) => ({
    entityProfile: {},
    saveEntityProfile: (v) =>
        set(() => ({
            entityProfile: v
        })),
    resetEntityProfile: () => set({ entityProfile: {} })
}))

export { usePersonalProfile, useEntityProfile }
