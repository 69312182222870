import serviceAxios from './index'

export default class NotificationAPI {
    // isGeneral = 1 returns general list, isGeneral = -1 returns approvals list
    static getNotificationList(payAccountId, isGeneral) {
        return serviceAxios.get(`notification/list/${payAccountId}/${isGeneral}`)
    }

    static getNotificationDetails(notificationId) {
        return serviceAxios.get(`notification/details/${notificationId}`)
    }

    //  Parameters:
    //  1. notificationId Integer
    //  2. approval Integer (1 approval -1 decline)
    static finalCheck(data) {
        return serviceAxios.post('notification/finalCheck', data)
    }

    // notification id
    static deleteNotification(id) {
        return serviceAxios.post(`notification/deleteNotification/${id}`)
    }

    static getNewNotificationsNumber() {
        return serviceAxios.get('notification/getNewNotificationsNumber')
    }
}
