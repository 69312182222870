const layout = {
    general: 'General',
    dashboard: 'Dashboard',
    notification: 'Notification',
    beneficiaries: 'Beneficiaries',
    sendMoney: 'Send Money',
    transaction: 'Transaction',
    tutorial: 'Tutorial',
    faq: 'FAQ',
    account: 'Account',
    signout: 'Sign out',
    signoutDesc: 'Are you sure you want to sign out?',
    confirm: 'Confirm',
    lng: 'English',
    stillThere: 'Are you still there?',
    stillThereDesc:
        'For security reasons, you will be logged out in <bold>{{time}} seconds</bold> due to inactivity.',
    logoutNow: 'Log out now',
    imStillHere: 'I’m still here'
}

export default layout
