const beneficiary = {
    beneficiaries: '收款人',
    beneficiariesList: '收款人列表',
    addMore: '添加更多',
    search: '搜索',
    accountName: '账户名称',
    beneficiaryType: '收款人类型',
    dob: '出生日期',
    streetName: '街道名称',
    streetNum: '街道号码',
    unitNum: '公寓、套间或单元号（可选）',
    city: '城市',
    province: '州/省',
    country: '国家',
    canada: '加拿大',
    unitedStates: '美国',
    sendMoney: '汇款',
    beneficiaryDetail: '收款人详情',
    virgocx: 'VirgoCX Inc.',
    edit: '编辑',
    delete: '删除',
    cancel: '取消',
    save: '保存',
    deleteBeneficiaryTitle: '确认删除',
    deleteBankTitle: '删除银行账户',
    deleteBeneficiaryDesc: '您确认要删除此收款人吗？',
    deleteBankDesc: '您确定要删除此银行账户吗？此操作无法撤销。',
    entity: '公司实体',
    individual: '个人',
    address: '地址',
    postalCode: '邮编',
    phone: '电话号码',
    email: '电子邮件地址',
    bankDetail: '银行信息',
    accountNumber: '账户号码',
    institutionNumber: '机构号码',
    transitNumber: '分行号码',
    swiftCode: 'SWIFT代码',
    bankName: '银行名称',
    bankAddress: '银行地址',
    bankPostalCode: '银行邮编',
    branchName: '分行名称',
    branchCityName: '分行城市名称',
    totalItems: '总计    项',
    selectBeneficiary: '选择收款人',
    beneficiary: '收款人',
    createBeneficiary: '创建新收款人',
    selectBank: '选择收款人银行详情',
    receiverBankNumber: '收款人银行机构号码',
    receiverTransitNumber: '收款人银行分行号码',
    receiverAccountNumber: '收款人银行账户号码',
    addBankAccount: '添加新银行账户',
    pendingMsg: '数据已更新，等待管理员确认。',
    noBeneficiary: '当前没有收款人。',
    editBeneficiarySubmitTitle: '收款人详情更新已提交',
    editBeneficiarySubmitDesc: '收款人信息将由授权签字人或管理员审核批准。已向他们发送通知。',
    addBankSubmitTitle: '新银行账户已提交',
    addBankSubmitDesc: '收款人银行账户将由授权签字人或管理员审核批准。已向他们发送通知。',
    addBeneficiarySubmitTitle: '新收款人已提交',
    addBeneficiarySubmitContent: '收款人信息将由授权签字人或管理员审核批准。已向他们发送通知。',
    confirmExit: '确认退出？',
    confirmExitContent: '您确定要退出吗？您的信息将不会被保存。',
    confirm: '确认',
    deleteBeneficiarySubmitTitle: '收款人删除已提交',
    deleteBeneficiarySubmitContent: '收款人信息将由授权签字人或管理员审核批准。已向他们发送通知。',
    deleteBankSubmitTitle: '银行删除已提交',
    deleteBankSubmitContent: '银行信息将由授权签字人或管理员审核批准。已向他们发送通知。',
    deletedBankDetails: '已删除的银行详情',
    accountNumberLengthError: '账户号码应至少为 7 位数字。',
    accountCurrency: '账户币种',
    ifIntermediaryBank: '如果收款行需要提供中转行信息，请输入中转行的详细信息（可选）',
    correspondentBankName: '中转银行名称',
    correspondentBankAddress: '中转银行地址',
    correspondentBankAccountNumber: '中转银行账户号码',
    correspondentBankSwiftNumber: '中转银行SWIFT',
    correspondentBankAba: '中转银行ABA(美国Routing Number)'
}

export default beneficiary
