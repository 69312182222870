import axios from 'axios'
import config from './config'

const CancelToken = axios.CancelToken

const pendingRequestList = new Map()

const country = 1

// 生成需要取消请求的key
const creatReqKey = (config) => {
    const { cancelKey = null } = config
    return cancelKey
}

// 把请求添加到队列中
const addPendingReqKey = (config) => {
    const cancelKey = creatReqKey(config)
    if (cancelKey) {
        config.cancelToken = new CancelToken((cancel) => {
            if (!pendingRequestList.has(cancelKey)) {
                pendingRequestList.set(cancelKey, cancel)
            }
        })
    }
}

const removePendingReqKey = (config) => {
    const cancelKey = creatReqKey(config)
    if (cancelKey) {
        if (pendingRequestList.has(cancelKey)) {
            const cancelToken = pendingRequestList.get(cancelKey)
            cancelToken()
            pendingRequestList.delete(cancelKey)
        }
    }
}

const removeFinishedReqKey = (config) => {
    const cancelKey = creatReqKey(config)
    if (cancelKey) {
        if (pendingRequestList.has(cancelKey)) {
            pendingRequestList.delete(cancelKey)
        }
    }
}

const blobInstance = axios.create({
    ...config,
    //   baseURL: process.env.NODE_ENV === 'production' ? '/'+url_suffix : 'https://virgocx.ca',//au加入之后404
    /* eslint-disable-next-line no-undef */
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 60000
})

// 添加请求拦截器
blobInstance.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        const auth = JSON.parse(localStorage?.getItem('payAuth')) ?? null
        const headers = auth
            ? {
                  'X-Requested-With': 'XMLHttpRequest',
                  lang: 'en',
                  country,
                  userId: auth.userId,
                  accessToken: auth.accessToken
              }
            : {
                  'X-Requested-With': 'XMLHttpRequest',
                  country,
                  lang: 'en'
              }
        Object.assign(config, { headers })
        removePendingReqKey(config)
        addPendingReqKey(config)
        return config
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 添加响应拦截器
blobInstance.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    removeFinishedReqKey(response.config)
    const res = response
    const { data } = res
    return data
})

/**
 * 错误弹窗 15s 一次
 * @param {*} msg
 * @returns
 */
// const throttleMsg = _.throttle(function (msg) {
//     message.error(msg)
// }, 15000)

export default blobInstance
