const layout = {
    general: '常规',
    dashboard: '数据面板',
    notification: '通知',
    beneficiaries: '收款人',
    sendMoney: '汇款',
    transaction: '交易',
    tutorial: '教程',
    faq: '常见问题',
    account: '账户',
    signout: '退出登录',
    signoutDesc: '您确定要退出登录吗？',
    confirm: '确认',
    lng: '简体中文',
    stillThere: '您还在线吗？',
    stillThereDesc: '处于安全因素的考虑，您的账户将在无操作的<bold>{{time}}秒</bold>后自动登出',
    logoutNow: '正在登出',
    imStillHere: '我还在线'
}

export default layout
