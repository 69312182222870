import cx from 'classnames'
import PropTypes from 'prop-types'
import { memo } from 'react'

export const NotificationBadge = memo(({ children, className }) => (
    <span
        className={cx(
            'flex items-center justify-center w-5 h-5 text-xs rounded-full bg-virgo-pay-gold font-inter text-virgo-pay-primary',
            className
        )}>
        {children}
    </span>
))

NotificationBadge.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
}
