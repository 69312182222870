const dashboard = {
    hello: '欢迎',
    totalBalance: '总余额',
    total: '总余额',
    asOn: '截至',
    availableBalance: '可用余额',
    liquidityReserve: '流动性储备',
    notification: '通知',
    pendingBalance: '待处理余额',
    createABeneficiary: '创建收款人',
    sendSingleEFT: '发送单笔EFT',
    sendWire: '发送电汇',
    sentEFT: '发送EFT',
    receivedEFT: '接收EFT',
    sentWire: '已发电汇',
    dateRange: '日期范围',
    type: '类型',
    status: '状态',
    clearFilter: '清除筛选',
    viewCompleteHistory: '查看完整历史记录',
    number: '编号',
    receiver: '收款人',
    dateAndTime: '日期和时间',
    amount: '金额',
    description: '描述',
    wireStatement: '汇款明细',
    viewAll: '查看全部',
    filter: '筛选',
    pendingTransac: '待处理交易',
    processedTransac: '已处理交易',
    settledTransac: '已结算交易',
    returnedTransac: '已退回交易',
    rejectedTransac: '已拒绝交易',
    recallTransac: '召回交易',
    pending: '待处理',
    processed: '已处理',
    settled: '已结算',
    returned: '已退回',
    rejected: '已拒绝',
    recall: '召回',
    wallet: '钱包',
    currency: '币种',
    unknown: '未知',
    allStatus: '全部状态',
    allWallet: '全部钱包',
    allCurrency: '全部币种',
    apply: '应用',
    selectCurrency: '币种选择',
    dateRangePlaceholder: '日期范围选择'
}

export default dashboard
