import { usePersonalProfile, useEntityProfile } from 'store/profile'

export function curryTimeTD(resolve, time = 60) {
    return function (reject) {
        let total = time
        resolve(total)
        let timer = setInterval(function () {
            if (--total < 1) {
                clearInterval(timer)
                reject()
            } else {
                resolve(total)
            }
        }, 1000)
        return timer
    }
}
export function logout() {
    // clear zustand profile
    const resetPersonalProfile = usePersonalProfile.getState().resetPersonalProfile
    const resetEntityProfile = useEntityProfile.getState().resetEntityProfile
    resetPersonalProfile()
    resetEntityProfile()
    localStorage.removeItem('payAuth')
    window.location.href = window.location.origin + '/page#/login'
}
export function isObjEmpty(obj) {
    return Object.keys(obj).length === 0
}
