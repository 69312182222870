const beneficiary = {
    beneficiaries: 'Beneficiaries',
    beneficiariesList: 'Beneficiaries List',
    addMore: 'Add more',
    search: 'Search',
    accountName: 'Name on Account',
    beneficiaryType: 'Beneficiary Type',
    dob: 'Date of Birth',
    streetName: 'Street Name',
    streetNum: 'Street Number',
    unitNum: 'Apt, Suite or Unit (optional)',
    city: 'City',
    province: 'State/Province',
    country: 'Country',
    canada: 'Canada',
    unitedStates: 'United States',
    sendMoney: 'Send Money',
    beneficiaryDetail: 'Beneficiary Detail',
    virgocx: 'VirgoCX Inc.',
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    save: 'Save',
    deleteBeneficiaryTitle: 'Confirm Deletion',
    deleteBankTitle: 'Delete Bank Account',
    deleteBeneficiaryDesc: 'Are you sure to delete this beneficiary?',
    deleteBankDesc:
        'Are you sure you want to delete this bank account? This action cannot be undone.',
    entity: 'Entity',
    individual: 'Individual',
    address: 'Address',
    postalCode: 'Postal Code',
    phone: 'Phone Number',
    email: 'Email Address',
    bankDetail: 'Bank Detail',
    accountNumber: 'Account Number',
    institutionNumber: 'Institution Number',
    transitNumber: 'Transit Number',
    swiftCode: 'SWIFT Code',
    bankName: 'Bank Name',
    bankAddress: 'Bank Address',
    bankPostalCode: 'Bank Postal Code',
    branchName: 'Branch Name',
    branchCityName: 'Branch City Name',
    totalItems: `Total {{itemsNum}} items`,
    selectBeneficiary: 'Select Beneficiary',
    beneficiary: 'Beneficiary',
    createBeneficiary: 'Create a new beneficiary',
    selectBank: 'Select beneficiary bank detail',
    receiverBankNumber: 'Receiver Bank Number',
    receiverTransitNumber: 'Receiver Transit Number',
    receiverAccountNumber: 'Receiver Account Number',
    addBankAccount: 'Add new bank account',
    pendingMsg: 'The data has been updated and is pending Admin confirmation.',
    noBeneficiary: 'Currently No beneficiary.',
    editBeneficiarySubmitTitle: 'Beneficiary detail update submitted',
    editBeneficiarySubmitDesc:
        'Beneficiary Information will be reviewed by an Authorized Signatory or Admin for approval. A notification has been sent to them',
    addBankSubmitTitle: 'New Bank Account Submitted',
    addBankSubmitDesc:
        'Beneficiary bank account will be reviewed by an Authorized Signatory or Admin for approval. A notification has been sent to them',
    addBeneficiarySubmitTitle: 'New Beneficiary submitted',
    addBeneficiarySubmitContent:
        'Beneficiary Information will be reviewed by an Authorized Signatory or Admin for approval. A notification has been sent to them',
    confirmExit: 'Confirm exit?',
    confirmExitContent: 'Are you sure you want to exit? Your information will not be saved.',
    confirm: 'Confirm',
    deleteBeneficiarySubmitTitle: 'Beneficiary Deletion submitted',
    deleteBeneficiarySubmitContent:
        'Beneficiary Information will be reviewed by an Authorized Signatory or Admin for approval. A notification has been sent to them',
    deleteBankSubmitTitle: 'Bank Deletion submitted',
    deleteBankSubmitContent:
        'Bank Information will be reviewed by an Authorized Signatory or Admin for approval. A notification has been sent to them',
    deletedBankDetails: 'Deleted Bank Details',
    accountNumberLengthError: 'Account number should be at least 7 digits',
    accountCurrency: 'Account Currency',
    ifIntermediaryBank:
        'If receiving bank requires an intermediary bank, please enter the banking details (Optional)',
    correspondentBankName: 'Intermediary Bank Name',
    correspondentBankAddress: 'Intermediary Bank Address',
    correspondentBankAccountNumber: 'Intermediary Bank Account Number',
    correspondentBankSwiftNumber: 'Intermediary Bank SWIFT Number',
    correspondentBankAba: 'Intermediary Bank ABA (USA Routing Number)'
}

export default beneficiary
